var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-vue-table',{ref:"table",staticClass:"pl-3 pr-3",attrs:{"title":"Email Templates","fields":_vm.fields,"url":_vm.listUrl,"per-page":10,"extra-params":{ start_date: _vm.start_date, status: _vm.status, end_date: _vm.end_date }},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"btn-group ml-3"},[_c('drop-btn',{staticClass:"ml-3",attrs:{"text":"Filters","icon":"fa fa-filter","size":"md","dropdown-width":"25r"}},[_c('div',{staticClass:"p-3"},[_c('validated-vue-select',{attrs:{"name":"Status","options":_vm.statusOptions,"label":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('btn',{attrs:{"color":"primary","size":"md","icon":"fa fa-plus","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})],1)]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('btn',{staticClass:"btn-basic-b",attrs:{"color":"primary","size":"xs","text":"Edit"},on:{"click":function($event){return _vm.setEdit(rowData)}}}),_c('delete-btn',{attrs:{"size":"xs","design":"basic"},on:{"click":function($event){return _vm.setDelete(rowData)}}})],1)]}}])}),_c('modal',{ref:"addModal",attrs:{"no-close-on-backdrop":"","title":"Add Template","width":"45r"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Name","name":"Name","rules":{required:true},"disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('validated-vue-select',{attrs:{"label":"Status","name":"Status","rules":{required:true},"options":_vm.statusOptions,"disabled":loading},model:{value:(model.status),callback:function ($$v) {_vm.$set(model, "status", $$v)},expression:"model.status"}}),_c('validated-file-input',{attrs:{"label":"Content","name":"Content"},model:{value:(model.content),callback:function ($$v) {_vm.$set(model, "content", $$v)},expression:"model.content"}}),_c('btn',{attrs:{"icon":"","text":"Save","size":"sm","loading":loading,"loading-text":"Saving..."}})]}}])})],1),_c('modal',{ref:"editModal",attrs:{"no-close-on-backdrop":"","title":"Add Template","width":"45r"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem},on:{"success":_vm.editComplete},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Name","name":"Name","rules":{required:true},"disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('validated-vue-select',{attrs:{"label":"Status","name":"Status","rules":{required:true},"options":_vm.statusOptions,"disabled":loading},model:{value:(model.status),callback:function ($$v) {_vm.$set(model, "status", $$v)},expression:"model.status"}}),_c('validated-file-input',{attrs:{"label":"Content","name":"Content"},model:{value:(model.content),callback:function ($$v) {_vm.$set(model, "content", $$v)},expression:"model.content"}}),_c('btn',{attrs:{"icon":"","text":"Save","size":"sm","loading":loading,"loading-text":"Saving..."}})]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('delete-animation'),_vm._v(" Please wait while we delete. ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the template. Are you sure?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }