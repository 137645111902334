<template>
    <div class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Email Marketing</h4>

        </div>
        <div class="card bs-4 p-0 of-h">
            <tabs class="">
                <tab title="Email Templates" class="p-0">
                    <email-listing :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :status="status.toString()" :searches="search"/>
                </tab>
                <tab title="Events" class="p-0">
                    <event-listing :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :status="status.toString()" :searches="search"/>
                </tab>
                <tab title="Campaigns" class="p-0">
                    <campaign-listing :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :status="status.toString()" :searches="search"/>
                </tab>
                <tab title="News Letter Subscriptions" class="p-0">
                    <news-letter-subscription :start_date="start_date.toString()" :end_date="end_date.toString()"
                                       :status="status.toString()" :searches="search"/>
                </tab>
            </tabs>
        </div>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import EmailListing from '../Marketing/EmailMarketing/ListingComponent';
import EventListing from '../Marketing/Event/ListingComponent';
import CampaignListing from '../Marketing/Campaign/ListingComponent';
import NewsLetterSubscription from '../Marketing/NewsLetterSubscription/ListingComponent';

export default {
    name       : 'ViewPage',
    components : { EmailListing, EventListing, CampaignListing, NewsLetterSubscription },
    data () {
        return {
            loading       : false,
            start_date    : '',
            search        : '',
            end_date      : '',
            status        : '',
            statusOptions : urls.purchaseAdmin.vendorOptions
        };
    }
};
</script>
