<template>
    <div>
        <custom-vue-table title="" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                         :page-number="false" :extra-params="{ start_date, vendor, end_date, searches, status }">
            <template #filters>
                <div class="btn-group ml-3">
                    <!-- <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn> -->
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" class="btn-basic-b" size="xs" @click="setEdit(rowData)" text="Edit"/>
                    <delete-btn color="primary" design="basic" size="xs" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>

        <modal no-close-on-backdrop title="News letter subscription edit" ref="editModal" width="45r">
            <b-form :save-url="addUrl" @success="editComplete" v-slot="{model, loading}" :initial-data="initialData">
                <validated-input label="Email" type="email" name="email" v-model="model.email" :rules="{required:true}"
                                 :disabled="loading"/>
                <checkbox-input label="Subscribed" name="subscribed" v-model="model.subscribed"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the template. Are you sure?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name  : 'ListingComponent',
    props : {
        start_date : { type : String },
        end_date   : { type : String },
        vendor     : { type : String },
        status     : { type : String },
        searches   : { type : String }
    },
    data () {
        return {
            loading      : false,
            listUrl      : urls.salesAdmin.marketing.emailMarketing.newsLetterSubscription.list,
            addUrl       : urls.salesAdmin.marketing.emailMarketing.newsLetterSubscription.addEdit,
            deleteUrl    : urls.salesAdmin.marketing.emailMarketing.newsLetterSubscription.delete,
            deletingItem : {
                id : ''
            },
            initialData : null,
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'email',
                    sortField : 'email',
                    title     : 'Email'
                },
                {
                    name      : 'subscribed',
                    sortField : 'subscribed',
                    title     : 'Subscribed'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        setEdit (item) {
            this.initialData = { ...item };
            this.$refs.editModal.show();
        },
        editComplete () {
            this.$refs.editModal.close();
            this.$refs.table.refreshTable();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            this.initialData = null;
            refs.table.refreshTable();
        }
    }
};
</script>
