<template>
    <div>
        <custom-vue-table class="pl-3 pr-3" title="Email Templates" :fields="fields" :url="listUrl" :per-page="10"
                          ref="table"
                          :extra-params="{ start_date, status, end_date }">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn>
                    <btn color="primary" size="md" @click="$refs.addModal.show()" icon="fa fa-plus" text="Add"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" class="btn-basic-b" size="xs" @click="setEdit(rowData)"
                         text="Edit"/>
                    <delete-btn size="xs" design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>

        <modal no-close-on-backdrop title="Add Template" ref="addModal" width="45r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <validated-vue-select label="Status" name="Status" v-model="model.status"
                                :rules="{required:true}" :options="statusOptions" :disabled="loading"/>
                <validated-file-input label="Content" name="Content" v-model="model.content"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <modal no-close-on-backdrop title="Add Template" ref="editModal" width="45r">
            <b-form :save-url="addUrl" @success="editComplete" v-slot="{model, loading}" :initial-data="editingItem">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <validated-vue-select label="Status" name="Status" v-model="model.status"
                                :rules="{required:true}" :options="statusOptions" :disabled="loading"/>
                <validated-file-input label="Content" name="Content" v-model="model.content"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the template. Are you sure?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'ListingComponent',
    data () {
        return {
            status        : '',
            listUrl       : urls.salesAdmin.marketing.emailMarketing.emailTemplates.list,
            addUrl        : urls.salesAdmin.marketing.emailMarketing.emailTemplates.addEdit,
            deleteUrl     : urls.salesAdmin.marketing.emailMarketing.emailTemplates.delete,
            statusOptions : [
                { value : 'Active', label : 'Active' },
                { value : 'Inactive', label : 'Inactive' },
                { value : 'Draft', label : 'Draft' }
            ],
            deletingItem : {
                id : ''
            },
            editingItem : null,
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        editComplete () {
            this.editingItem = null;
            this.$refs.editModal.close();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        },
        async suspendTemplate (item) {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.salesAdmin.customers.suspend, { user : item.id });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Updated status successfully', 'Success', {
                    type : 'success'
                });
                that.formSuccess();
                that.loading = false;
            } else {
                that.$notify('Sorry, Please try again later', 'Failed', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        }

    }
};
</script>
