<template>
    <div>
        <custom-vue-table class="pl-3 pr-3" title="Email Templates" :fields="fields" :url="listUrl" :per-page="10"
                          ref="table" :extra-params="{ start_date, status, end_date }">
            <template #filters>
                <div class="btn-group ml-3">
                    <!-- <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn> -->
                    <btn color="primary" size="md" @click="$refs.addModal.show()" icon="fa fa-plus" text="Add"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" class="btn-basic-b" size="xs" @click="setEdit(rowData)" text="Edit"/>
                    <delete-btn color="primary" design="basic" size="xs" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>

        <modal no-close-on-backdrop title="Add Event" ref="addModal" width="45r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <textarea-input name="Description" :rules="{required:true}"
                                label="Description" v-model="model.description"/>
                <validated-date-picker label="From Date" name="from_date" v-model="model.from_date"
                                       :rules="{required:true}"
                                       :disabled="loading"/>
                <validated-date-picker label="To Date" name="to_date" v-model="model.to_date"
                                       :rules="{required:true}"
                                       :disabled="loading"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <modal no-close-on-backdrop title="Edit Event" ref="editModal" width="45r">
            <b-form :save-url="addUrl" @success="editComplete" v-slot="{model, loading}" :initial-data="editingItem">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <textarea-input name="Description" :rules="{required:true}"
                                label="Description" v-model="model.description"/>
                <validated-date-picker label="From Date" name="from_date" v-model="model.from_date"
                                       :rules="{required:true}"
                                       :disabled="loading"/>
                <validated-date-picker label="To Date" name="to_date" v-model="model.to_date"
                                       :rules="{required:true}"
                                       :disabled="loading"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the template. Are you sure?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name  : 'ListingComponent',
    props : {
        start_date : { type : String },
        end_date   : { type : String },
        vendor     : { type : String },
        status     : { type : String },
        searches   : { type : String }
    },
    data () {
        return {
            loading       : false,
            addUrl        : urls.salesAdmin.marketing.emailMarketing.event.addEdit,
            listUrl       : urls.salesAdmin.marketing.emailMarketing.event.list,
            deleteUrl     : urls.salesAdmin.marketing.emailMarketing.event.delete,
            statusOptions : [
                { value : true, label : 'True' },
                { value : false, label : 'False' }
            ],
            deletingItem : { id : '' },
            editingItem  : null,
            fields       : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'from_date',
                    sortField : 'from_date',
                    title     : 'From Date'
                },
                {
                    name      : 'to_date',
                    sortField : 'to_date',
                    title     : 'End Date'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/purchase/return/' + rowData.id + '/details/');
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        editComplete () {
            this.$refs.editModal.close();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteComplete () {
            const ref = this.$refs;
            ref.deleteModal.close();
            ref.table.refreshTable();
        }
    }
};
</script>
