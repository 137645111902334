<template>
    <div>
        <custom-vue-table title="" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                         :page-number="false" :extra-params="{ start_date, vendor, end_date, searches, status }">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                        <div class="p-3">
                            <validated-vue-select name="Status" :options="statusOptions"
                                                  label="Status"
                                                  v-model="status"/>
                        </div>
                    </drop-btn>
                    <btn color="primary" size="md" @click="$refs.addModal.show()" icon="fa fa-plus" text="Add"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <btn color="primary" class="btn-basic-b" size="xs" @click="setEdit(rowData)" text="Edit"/>
                    <delete-btn color="primary" design="basic" size="xs" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>

        <modal no-close-on-backdrop title="Add Cart" ref="addModal" width="45r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <validated-input label="Subject" name="Subject" v-model="model.subject" :rules="{required:true}"
                                 :disabled="loading"/>
                <validated-ajax-vue-select name="Event" :url="eventOptions"
                                      :rules="{required:true}" label="Event" v-model="model.event"/>
                <validated-ajax-vue-select name="Email template" :url="emailOptions"
                                      :rules="{required:true}" label="Email template" v-model="model.email_template"/>
                <validated-vue-select name="Status" :options="statusOptions"
                                      :rules="{required:true}" label="Status" v-model="model.status"/>
                <validated-ajax-vue-select name="Customer group" :url="groupOptions"
                                      :rules="{required:true}" label="customer_group" v-model="model.customer_group"/>
                <validated-vue-select name="channel" :options="channelOptions"
                                      :rules="{required:true}" label="channels" v-model="model.channel"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <modal no-close-on-backdrop title="Edit Campaign" ref="editModal" width="45r">
            <b-form :save-url="editUrl" @success="formSuccess" v-slot="{model, loading}"  :initial-data="editingItem">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                                 :disabled="loading"/>
                <validated-input label="Subject" name="Subject" v-model="model.subject" :rules="{required:true}"
                                 :disabled="loading"/>
                <validated-ajax-vue-select name="Event" :url="eventOptions"
                                      :rules="{required:true}" label="Event" v-model="model.event"/>
                <validated-ajax-vue-select name="Email template" :url="emailOptions"
                                      :rules="{required:true}" label="Email template" v-model="model.email_template"/>
                <validated-vue-select name="Status" :options="statusOptions"
                                      :rules="{required:true}" label="Status" v-model="model.status"/>
                <validated-ajax-vue-select name="Customer group" :url="groupOptions"
                                      :rules="{required:true}" label="customer_group" v-model="model.customer_group"/>
                <validated-vue-select name="channel" :options="channelOptions"
                                      :rules="{required:true}" label="channels" v-model="model.channel"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the template. Are you sure?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name  : 'ListingComponent',
    props : {
        start_date : { type : String },
        end_date   : { type : String },
        vendor     : { type : String },
        status     : { type : String },
        searches   : { type : String }
    },
    data () {
        return {
            loading       : false,
            listUrl       : urls.salesAdmin.marketing.emailMarketing.campaign.list,
            addUrl        : urls.salesAdmin.marketing.emailMarketing.campaign.add,
            editUrl       : urls.salesAdmin.marketing.emailMarketing.campaign.edit,
            deleteUrl     : urls.salesAdmin.marketing.emailMarketing.campaign.delete,
            eventOptions  : urls.salesAdmin.marketing.emailMarketing.event.vueSelect,
            emailOptions  : urls.salesAdmin.marketing.emailMarketing.emailTemplates.vueSelect,
            groupOptions  : urls.salesAdmin.MasterData.Group.vueSelect,
            statusOptions : [
                { value : 'Active', label : 'Active' },
                { value : 'Inactive', label : 'Inactive' }
            ],
            channelOptions : [
                { label : 'Mobile Application', value : 'Mobile Application' },
                { label : 'Website', value : 'Website' }
            ],
            deletingItem : {
                id : ''
            },
            editingItem : null,
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'subject',
                    sortField : 'subject',
                    title     : 'Subject'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            this.editingItem = null;
            refs.table.refreshTable();
        }
    }
};
</script>
